import { GetServerSideProps } from 'next'

import { List } from '../../components/List/List'
import { ListItemTicket } from '../../components/List/ListItemTicket/ListItemTicket'
import { BestPriceGuarantee } from '../../components/Banners/BestPriceGuarantee/BestPriceGuarantee'
import { FloatingCart } from '../../components/Cart/FloatingCart/FloatingCart'
import { ContinueLink } from '../../components/Misc/ContinueLink/ContinueLink'
import { TextApiResponseType, TextType } from '../../types/TextType'
import { PageView } from '../../components/Analytics/PageView/PageView'

import type { ProductType } from '../../types/ProductType'
import { useContext, useEffect } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { YearContext } from '../../contexts/YearContext';
import Head from 'next/head';

const ticketIds = [234481, 234771] // 2 Park and 3 Park tickets
const textTypes = ['ticket_page_intro']

function ParkTickets({ products, texts }: { products: Array<ProductType>, texts: Array<TextType> }) {
  const {setYear} = useContext(YearContext)

  const title = "Tickets"
  const introText = texts.find(t => t.text_type === 'ticket_page_intro')?.body ?? ''

  const {cart} = useContext(CartContext)
  useEffect(()=>{
    cart.data.tickets.map(ticket => {
      if(ticket.tags.includes('rooms')){
        setYear && setYear(ticket.date.split('-').slice(0,1).join())
      }
    })
  },[cart,setYear])
  return (
    <>
      <Head key={'park-tickets'}><title>Universal Orlando™ | Park Tickets</title></Head>
      <PageView
        pageName='Park Tickets'
        productsOnPage={ products }
      />
      <main className='products'>
        <FloatingCart showNextStep={true} currentSection='tickets' placement="top" />
        <ContinueLink currentSection='tickets' />
        <List title={title} introText={introText} rating={''}>
          {
            products.map((product: ProductType) => {

              return (
                <ListItemTicket
                  key={product.id}
                  product={product}
                />
              )
            })
          }
        </List>
        <FloatingCart showNextStep={true} currentSection='tickets' placement="bottom" />
      </main>
      <BestPriceGuarantee />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {

  // Get products:
  const fetches = ticketIds.map(ticketId => fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/products/${ticketId}?view=website`))
  const results = await Promise.all(fetches)
  const products: Array<ProductType | null> = await Promise.all(results.map(result => {

    if (result.statusText === 'OK') {
      return result.json()
    } else {
      return null
    }
  }))

  // Get page text:
  const textResult = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/text?types=${textTypes.join(",")}`)
  const texts: TextApiResponseType = await textResult.json()

  return { props: {
    products: products.filter(p => p !== null),
    texts: texts.data,
  }}
}

export default ParkTickets

