import {
  ReactElement,
  useState,
  useEffect,
} from 'react';
import ReactHtmlParser from 'react-html-parser';

import styles from './TicketPopup.module.scss'

import Popup from 'reactjs-popup'

import { ProductType } from '../../../../types/ProductType'
import { DataLayerAPI } from '../../../../api/DataLayerAPI';

type TicketPopupProps = {
  children: JSX.Element,
  product: ProductType,
  adultQtyPicker: JSX.Element | null,
  childQtyPicker: JSX.Element | null,
  yearPicker: JSX.Element | null,
  addToBasketBtn: (callback: () => void) => ReactElement,
}

const dataLayerAPI = new DataLayerAPI()

export function TicketPopup({ children, product, adultQtyPicker, childQtyPicker, yearPicker, addToBasketBtn }: TicketPopupProps) {

  // There's currently a bug with reactjs-popup which means the server-side
  // output will be slightly different to the client-side output, resulting
  // in a warning on the client side.
  // https://github.com/yjose/reactjs-popup/issues/215
  // So here we're using useEffect() to only render the popup on the client side.
  const [ renderPopup, setRenderPopup ] = useState(false)
  useEffect(() => setRenderPopup(true), [ setRenderPopup ])

  const [showDetails, setShowDetails] = useState(false);

  const handleToggleClick = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return renderPopup ? (
    <Popup trigger={children} modal lockScroll onOpen={() => dataLayerAPI.logModalAction('Modal Opened','Ticket Deatils',product.title)} onClose={() => dataLayerAPI.logModalAction('Modal Closed','Ticket Deatils',product.title)}>
      {(close: Function) => (
        <div className={styles.modal}>
          <button className={styles.close} onClick={ () => close() }>
            <span className='visuallyHidden'>Close</span>
          </button>
          <div className={styles.popupHeader}>
            <h3>{ product.title }</h3>
          </div>
          <div className={styles.popupBody}>
            <div>
              <h4>Description</h4>
              { ReactHtmlParser(product.desc) }
              <h4>Ticket includes</h4>
              { ReactHtmlParser(product.ticket_includes) }
              <h4>
                <a className={`${styles.toggle} ${showDetails ? styles.open : ''}`} href="#na" onClick={handleToggleClick}>Conditions</a>
              </h4>
              {showDetails === true && ReactHtmlParser(product.conditions) }
            </div>
            <div>
              { yearPicker }
              { adultQtyPicker }
              { childQtyPicker }
            </div>
          </div>
          <div className={styles.popupFooter}>
            <button
              className={styles.btnAlt}
              onClick={ () => close() }>CANCEL</button>
            { addToBasketBtn(() => close()) }
          </div>
        </div>
      )}
    </Popup>
  ) : null
}
